import gql from 'graphql-tag'

export const GET_TALENT_AREA = gql`
    query getTalentArea($childOf: String, $toplevel: Boolean) {
        getTalentArea(childOf: $childOf, toplevel: $toplevel) {
            id
            name
            order
            level
            parent
        }
    }
`

export const GET_ALL_COUNTRIES = gql`
    query allCountries {
        allCountries {
            id
            code
            name
            enabled
        }
    }
`

export const GET_TIMEZONES = gql`
    query allTimezones {
        allTimezones {
            id
            name
        }
    }
`

export const GET_LANGUAGES = gql`
    query allLanguages {
        allLanguages {
            id
            name
            localName
        }
    }
`