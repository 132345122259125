<template>
  <div>
    <v-card
      flat
      tile
      color="grey"
      class="home-main-image"
      :img="require('@/assets/images/home-screen/background-section-1.webp')"
      alt="background-section"
    >
      <!-- :height="isMdAndAbove ? '120vh' : 'auto'"  -->
      <!-- [TODO] :style="!isMdAndAbove ? 'margin-top:11rem' : null"  -->
      <!-- data-aos="fade-up" data-aos-duration="1500" -->
      <v-container>
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="home-main-section home-main-image"
        >
          <v-flex
            xl9
            lg9
            md7
            sm12
            xs12
            py-md-10
          >
            <v-card-text class="text--primary font-weight-bold py-0 pl-0">
              <h1
                :class="{ 'banner-heading': $device.mobile }"
                class="text-lg-h1 text-md-h2 text-sm-h2 mb-sm-0 pb-sm-0"
              >
                {{ $t('Homepage_banner_msg') }} <br>
                {{ $t('Homepage_banner_msg_sub') }}
              </h1>
            </v-card-text>
            <v-card-text
              class="text--secondary text-sm-h5 text-h4 font-weight-medium py-0 pl-0 mt-4"
            >
              <span>{{ $t('authentic_connections') }}</span><br>
            </v-card-text>
            <v-card-text class="pl-0">
              <v-btn
                color="secondary"
                class="white--text"
                to="/signup"                
              >
                {{ $t('explore_talent') }}
              </v-btn>
            </v-card-text>
          </v-flex>
          <v-flex
            xl3
            lg3
            md5
            sm12
            xs12
            text-md-right
            text-center
            align-self-center
            class="banner-video"
          >
            <div class="experience">
              <div>
                <img
                  id="phone"
                  ref="phone"
                  src="/images/home/phone.png"
                  alt="phone"
                >
              </div>
              <div
                id="talent-video"
                class="talent-video"
              >
                <video
                  v-if="showVideo"
                  autoplay
                  muted
                  loop
                  class="video side"
                >
                  <source
                    src="https://rave-static-assets.s3.us-east-2.amazonaws.com/vid3-480.8557afee.mp4"
                    type="video/mp4"
                  >
                </video>
              </div>
              <div class="fan-video">
                <video
                  autoplay
                  muted
                  loop
                  class="video side"
                >
                  <source
                    src="https://d31atom79plcxl.cloudfront.net/vid1-480.65c9d810.mp4"
                    type="video/mp4"
                  >
                </video>
              </div>
            </div>
          </v-flex>
        </v-row>
      </v-container>
    </v-card>
    <!-- <v-card
      flat
      tile
      color="white"
      :height="isMdAndAbove ? '800' : null"
      :class="isMdAndAbove ? 'section' : 'py-10 px-3'"
    >
      <v-container>
        <v-card-text
          class="text--primary text-lg-h2 pa-0 ma-0 pb-3 pl-0 mt-md-0 mt-sm-6 section-heading"
        >
          {{ $t('featured_talent') }}
        </v-card-text>
        <v-slide-group
          v-if="featuredLoading"
          :show-arrows="false"
        >
          <v-slide-item
            v-for="n in 10"
            :key="n"
          >
            <v-skeleton-loader
              class="mx-2 featured-talent-loader"
              width="350"
              type="image, list-item-two-line"
            />
          </v-slide-item>
        </v-slide-group>
        <splide
          v-else
          ref="mainSlider"
          :options="splideOptions"
        >
          <splide-slide
            v-for="(talent, index) in allFeaturedTalent"
            :key="index"
          >
            <v-hover v-slot="{ hover }">
              <v-img
                :src="talent.user.profileImg"
                class="white--text align-end img-border-radius"
                eager
                lazy-src="@/assets/images/default.png"
                alt="default"
                gradient="rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)"
              >
                <v-card-text class="text--primary pl-5 pb-11 ml-3">
                  <p
                    v-if="!hover"
                    class="ma-0 white--text text-h3"
                  >
                    {{ talent.user.firstName }} {{ talent.user.lastName }}
                  </p>
                  <p
                    v-if="!hover"
                    class="ma-0 white--text text-h5 mt-2"
                  >
                    {{ getFirstTalentArea(talent.areas) }}
                  </p>

                  <v-scroll-y-reverse-transition hide-on-leave>
                    <p
                      v-if="hover"
                      class="ma-0 white--text text-h3 transition-ease-in-out"
                    >
                      {{ talent.user.firstName }} {{ talent.user.lastName }}
                    </p>
                  </v-scroll-y-reverse-transition>
                  <v-scroll-y-reverse-transition hide-on-leave>
                    <p
                      v-if="hover"
                      class="ma-0 white--text text-h5 mt-2 transition-ease-in-out"
                    >
                      {{ getFirstTalentArea(talent.areas) }}
                    </p>
                  </v-scroll-y-reverse-transition>
                  <v-scroll-y-reverse-transition leave-absolute>
                    <v-btn
                      v-if="hover"
                      color="secondary"
                      class="transition-ease-in-out white--text mt-3"
                      :to="`/talent/profile/${talent.user.username}`"
                    >
                      {{ $t('book_now') }}
                    </v-btn>
                  </v-scroll-y-reverse-transition>
                </v-card-text>
              </v-img>
            </v-hover>
          </splide-slide>
        </splide>
      </v-container>
    </v-card> -->
    <v-card
      flat
      tile
      color="grey"
      :height="isMdAndAbove ? '670' : null"
    >
      <v-container>
        <v-row
          no-gutters
          justify="center"
          class="home-featured-talent"
          :class="isMdAndAbove ? 'section' : 'py-10 px-3'"
        >
          <v-card-text
            class="text--primary text-lg-h2 pl-md-0 section-heading mb-8"
            data-aos="fade-up"
            data-aos-duration="4000"
          >
            {{ $t('what_is_with') }}
          </v-card-text>
          <v-flex
            xs12
            sm12
            md4
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <v-card-text class="text--primary pl-md-0">
              <img
                src="@/assets/images/home-screen/03-for-everyone.svg"
                alt="face-to-face"
                width="72"
                height="72"
              >
              <p class="text-lg-h3 text-sm-h4 text-warp pt-5 font-weight-bold">
                {{ $t('exp_authentic_connectn') }}
              </p>
              <p
                class="text-lg-h4 text--secondary font-weight-medium text-sm-h6 light-text text-warp mt-n1"
              >
                {{ $t('face_video_conf') }}<br>{{ $t('no_crowds_no_rush') }}
              </p>
            </v-card-text>
          </v-flex>
          <v-flex
            xs12
            sm12
            md4
            data-aos="fade-up"
            data-aos-duration="2300"
          >
            <v-card-text class="text--primary">
              <img
                src="@/assets/images/home-screen/02-with-anyone.svg"
                alt="professionals"
                width="72"
                height="72"
              >
              <p class="text-lg-h3 text-sm-h4 text-warp pt-5 font-weight-bold">
                {{ $t('meet_with_anyone') }}
              </p>
              <p
                class="text-lg-h4 text-sm-h6 text--secondary font-weight-medium text-warp mt-n1"
              >
                {{ $t('nobody_is_out') }}
              </p>
              <p
                class="text-lg-h4 text-sm-h6 text--secondary font-weight-medium text-warp mt-n3"
              >
                {{ $t('nobody_is_out_sub') }}
              </p>
              <p
                class="text-lg-h4 text-sm-h6 text--secondary font-weight-medium text-warp mt-n3"
              >
                {{ $t('nobody_is_out_sub2') }}
              </p>
            </v-card-text>
          </v-flex>
          <v-flex
            xs12
            sm12
            md4
            data-aos="fade-up"
            data-aos-duration="2700"
          >
            <v-card-text class="text--primary">
              <img
                src="@/assets/images/home-screen/01-one-on.svg"
                alt="from-anywhere"
                width="72"
                height="72"
              >
              <p class="text-lg-h3 text-sm-h4 text-warp pt-5 font-weight-bold">
                {{ $t('with_from_anywhere') }}
              </p>
              <p
                class="text-lg-h4 text-sm-h6 text--secondary font-weight-medium text-warp mt-n1"
              >
                {{ $t('any_device_connect_anywhere') }}
              </p>
              <p
                class="text-lg-h4 text-sm-h6 text--secondary font-weight-medium text-warp mt-n3"
              >
                {{ $t('any_device_connect_anywhere_sub') }}
              </p>
            </v-card-text>
          </v-flex>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      flat
      tile
      color="grey"
      :height="isMdAndAbove ? '670' : null"
    >
      <v-container>
        <v-row
          align="start"
          justify="start"
          class="home-featured-talent"
          :class="isMdAndAbove ? 'section' : 'py-10 px-3'"
        >
          <v-card-text
            class="text--primary text-lg-h2 pl-md-0 section-heading mb-8"
          >
            {{ $t('how_do_with') }}
          </v-card-text>
          <v-flex
            xs12
            sm12
            md4
            class="position-relative"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            <div
              v-if="!isMobile"
              class="how-do-i-with-line"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              &nbsp;
            </div>
            <div
              v-if="isMobile"
              class="how-do-i-with-vertical-line"
              data-aos="fade-down"
              data-aos-duration="1200"
            >
              &nbsp;
            </div>
            <v-card-text class="text--primary pl-md-0">
              <v-row no-gutters>
                <v-col
                  lg="12"
                  md="12"
                  sm="2"
                  xs="2"
                >
                  <img
                    src="@/assets/images/home-screen/step-1.svg"
                    alt="favorite"
                    width="72"
                    height="72"
                  >
                </v-col>
                <v-col
                  md="12"
                  class="ml-md-0 ml-sm-10 ml-xs-10 ml-lg-0"
                >
                  <p class="text-lg-h3 text-sm-h4 text-warp pt-lg-5">
                    {{ $t('find_favorite_influencer') }}
                  </p>
                  <p
                    class="text-lg-h4 text-sm-h6 light-text text--secondary text-warp mt-n2"
                  >
                    {{ $t('talent_directory_to_connect') }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-flex>
          <v-flex
            xs12
            sm12
            md4
            class="position-relative"
            data-aos="fade-up"
            data-aos-duration="2200"
          >
            <div
              v-if="!isMobile"
              class="how-do-i-with-line ml-2"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              &nbsp;
            </div>
            <v-card-text class="text--primary">
              <v-row no-gutters>
                <v-col
                  lg="2"
                  md="2"
                  sm="2"
                  xs="2"
                >
                  <img
                    src="@/assets/images/home-screen/step-2.svg"
                    alt="booking"
                    width="72"
                    height="72"
                  >
                </v-col>
                <v-col
                  md="12"
                  class="ml-md-0 ml-sm-10 ml-xs-10 ml-lg-0"
                >
                  <p class="text-lg-h3 text-sm-h4 text-warp pt-lg-5">
                    {{ $t('book_time') }}
                  </p>
                  <p
                    class="text-lg-h4 text-sm-h6 light-text text--secondary text-warp mt-lg-n2"
                  >
                    {{ $t('pick_avail_time_session') }}
                  </p>
                  <p
                    class="text-lg-h4 text-sm-h6 light-text text--secondary text-warp mt-n3"
                  >
                    {{ $t('pick_avail_time_session_sub') }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-flex>
          <v-flex
            xs12
            sm12
            md4
            data-aos="fade-up"
            data-aos-duration="2200"
          >
            <v-card-text class="text--primary">
              <v-row no-gutters>
                <v-col
                  lg="12"
                  md="12"
                  sm="2"
                  xs="2"
                >
                  <img
                    src="@/assets/images/home-screen/step-3.svg"
                    alt="connect"
                    width="72"
                    height="72"
                  >
                </v-col>
                <v-col
                  md="12"
                  class="ml-md-0 ml-sm-10 ml-xs-10 ml-lg-0"
                >
                  <p class="text-lg-h3 text-sm-h4 text-warp pt-lg-5">
                    {{ $t('connect') }}
                  </p>
                  <p
                    class="text-lg-h4 text-sm-h6 light-text text--secondary text-warp mt-n2"
                  >
                    {{ $t('with_opens_door') }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-flex>
          <v-flex
            v-if="!isMobile"
            xs12
            sm12
            pt-md-16
            pt-5
          >
            <v-card-text class="text--primary">
              <v-btn
                color="secondary"
                class="white--text ml-n4"
                to="/signup"
              >
                {{ $t('explore_talent') }}
              </v-btn>
            </v-card-text>
          </v-flex>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      ref="marketPlace"
      flat
      tile
      color="white"
    >
      <v-container>
        <div class="talent-section">
          <v-card-text class="text--primary text-lg-h2 pl-0 section-heading">
            {{ $t("marketplace") }}
          </v-card-text>
        </div>
        <div
          :class="isMdAndAbove ? 'talent-section' : 'px-3'"
          class="overflow-x-auto"
        >
          <v-btn-toggle
            v-model="category"
            tile
            group
          >
            <v-btn
              v-for="(talentCategory, index) in talentCategories"
              :key="talentCategory.id"
              text
              tile
              color="secondary"
              class="black--text subtitle-1 font-weight-bold text--primary pl-6 pr-6"
              :value="talentCategory.name"
              @click="getTalentSubCategories(talentCategory.name)"
            >
              {{ getSwitchBoardCategory(index) }}
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-container>
      <v-card
        v-if="talentSubCategories.length"
        flat
        tile
        color="grey"
      >
        <v-container>
          <div
            :class="isMdAndAbove ? 'talent-section' : 'px-3'"
            class="overflow-x-auto"
          >
            <v-btn-toggle
              v-model="subCategory"
              group
              class="sub-category"
            >
              <v-btn
                v-for="talentSubCategory in talentSubCategories"
                :key="talentSubCategory.id"
                class="black--text"
                :value="talentSubCategory.id"
                text
                tile
                :style="
                  subCategory != talentSubCategory.id
                    ? 'background-color: white !important;'
                    : null
                "
                @click="
                  ;(filter.talentSubCategoryId =
                    filter.talentSubCategoryId == ''
                      ? talentSubCategory.name
                      : ''),
                    searchTalent()
                "
              >
                {{ talentSubCategory.name }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-container>
      </v-card>
      <div :class="isMdAndAbove ? 'talent-section' : 'px-3'">
        <v-container>
          <v-row
            no-gutters
            align="center"
          >
            <!-- <v-flex
              xs12
              sm12
              md2
              mt-5
            >
              <v-text-field
                v-model="filter.city"
                class="talent-filter"
                placeholder="City"
                background-color="grey"
                :rules="rules.city_name_homepage"
                outlined
                height="48"
                hide-details="auto"
                @keyup="searchTalent()"
              />
            </v-flex>
            <v-flex
              xs12
              sm12
              md2
              mt-5
              ml-md-10
            >
              <v-text-field
                v-model="filter.zipcode"
                class="talent-filter"
                placeholder="Zip Code"
                :rules="rules.zipCode_homepage"
                background-color="grey"
                outlined
                height="48"
                hide-details="auto"
                @keyup="searchTalent()"
              />
            </v-flex> -->
            <!-- <v-flex
              xs12
              sm12
              md3
              mt-5
              ml-auto
              text-right
              class="sort-by"
            >
              <p class="d-inline text-subtitle-2">
                {{ $t("sort_by") }}
              </p>
              <v-btn-toggle
                class="d-inline"
                active-class="orange--text"
                value="popular"
              >
                <v-btn
                  text
                  value="popular"
                  small
                  class="sort-by-btn text-subtitle-1 mt-n1 ml-0 black--text"
                >
                  {{ $t("popular") }}
                </v-btn>
                <v-btn
                  text
                  value="relevance"
                  small
                  class="sort-by-btn text-subtitle-1 black--text mt-n1 font-weight-bold ml-n3"
                >
                  {{ $t("relevance") }}
                </v-btn>
              </v-btn-toggle>
            </v-flex> -->
          </v-row>
        </v-container>
        <v-container>
          <v-row
            no-gutters
            class="mt-5"
          >
            <template v-if="featuredLoading">
              <v-slide-item
                v-for="n in 20"
                :key="n"
              >
                <v-skeleton-loader
                  class="ma-2"
                  width="200"
                  type="image, list-item-two-line"
                />
              </v-slide-item>
            </template>
            <template v-else-if="paginatedTalents && paginatedTalents.length">
              <v-col
                v-for="(talent, index) in paginatedTalents"
                :key="index"
                xs="6"
                md="auto"
                sm="6"
              >
                <v-hover
                  v-slot="{ hover }"
                  :key="index"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <v-card
                    flat
                    class="ma-2"
                    rounded="lg"
                  >
                    <v-img
                      :src="
                        talent.user.profileImg
                          ? talent.user.profileImg
                          : $defaultImg
                      "
                      class="white--text align-end"
                      eager
                      width="200"
                      height="213"
                      lazy-src="@/assets/images/default.png"
                      alt="default"
                      gradient="rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)"
                    >
                      <v-card-text class="text--primary px-5">
                        <p class="ma-1 white--text font-weight-bold">
                          {{ talent.user.firstName }} {{ talent.user.lastName }}
                        </p>
                        <p class="ma-1 white--text pt-1">
                          {{ getFirstTalentArea(talent.areas) }}
                        </p>
                        <div
                          v-if="!hover"
                          class="d-flex"
                        >
                          <p
                            class="ma-1 white--text d-inline pl-1 pt-1 ml-0 font-weight-bold"
                          >
                            ${{ talent.price }}
                          </p>
                        </div>
                        <v-scroll-y-reverse-transition leave-absolute>
                          <v-btn
                            v-if="hover"
                            transition="scroll-y-reverse-transition"
                            duration="40"
                            color="secondary"
                            small
                            class="transition-ease-in-out white--text"
                            :to="`/talent/profile/${talent.user.username}`"
                          >
                            {{ $t('book_now') }}
                          </v-btn>
                        </v-scroll-y-reverse-transition>
                      </v-card-text>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-col>
            </template>
            <v-row
              v-else
              no-gutters
              align="center"
              justify-md="center"
              justify="center"
            >
              <v-alert
                border="bottom"
                colored-border
                type="warning"
                elevation="2"
              >
                {{ $t('talent_not_found') }}
              </v-alert>
            </v-row>
          </v-row>
          <v-row
            v-if="isShowLoadMore && isQuerySearch"
            no-gutters
            align="center"
            justify="center"
            class="my-5"
          >
            <v-btn
              color="primary"
              :loading="loading"
              class="transition-ease-in-out white--text"
              @click="loadMore()"
            >
              {{ $t('load_more') }}
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </v-card>
  </div>
</template>
<script>
// graphql
import {
  GET_ALL_TALENT,
  SEARCH_TALENT,
  FEATURED_TALENT,
} from '@/graphql/talent'
import { GET_TALENT_AREA } from '@/graphql/master'
import { rules } from '@/utils/validation.js'

import { Splide, SplideSlide } from '@splidejs/vue-splide'

import { switchBoardCategories } from '@/utils/string'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'Home',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      // Variables added from old data
      rules,
      showSnackbar: false,
      snackbarMessage: '',
      featuredLoading: false,
      talentLoading: false,
      allTalent: [],
      allFeaturedTalent: [],
      filteredTalent: [],
      availableGenres: [],
      selected: [],
      allTalentAssetMapping: {},
      featuredTalentAssetMapping: {},
      profile: '',
      category: null,
      subCategory: null,
      item: '/audience-medium.jpg',
      tab: 0,
      text: 'Lorem ipsum dolor sit amet',
      talentCategories: [],
      talentSubCategories: [],
      options: {
        duration: 500,
        easing: 'easeInCubic',
      },
      paginatedTalents: [],
      paginatedFeaturedTalents: [],
      loading: false,
      showVideo: false,
      isQuerySearch: true,
      filter: {
        city: '',
        zipcode: '',
        talentSubCategoryId: '',
      },
      first: 15,
      skip: 0,
      totalTalentCount: 0,
    }
  },
  computed: {
    isMdAndAbove() {
      return ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    splideOptions() {
      if (this.isMdAndAbove) {
        return {
          rewind: true,
          perPage: 3,
          height: 550,
          gap: '1rem',
          fixedWidth: 380,
          fixedHeight: 550,
          cover: true,
        }
      } else {
        return {
          rewind: true,
          perPage: 1,
          height: 550,
          fixedHeight: 550,
          cover: true,
        }
      }
    },
    isShowLoadMore() {
      return this.paginatedTalents.length == this.totalTalentCount
        ? false
        : true
    },
    getFirstTalentArea() {
      return talentAreas => (talentAreas.length ? talentAreas.filter(el=> el.level === 2)[0].name : null)
    },
  },
  created() {
    window.addEventListener('resize', this.myEventHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.myEventHandler)
  },
  mounted() {
    this.getAllTalent()
    this.getFeaturedTalent()
    this.getTalentCategories()
  },
  beforeUpdate() {
    const phoneHeight = this.$refs.phone.clientHeight - 40
    const phoneWidth = this.$refs.phone.clientWidth - 30

    let talentVideo = document.querySelector('#talent-video')
    talentVideo.style.width = phoneWidth + 'px'
    talentVideo.style.height = phoneHeight + 'px'

    this.showVideo = true
  },
  methods: {
    myEventHandler(e) {
      // your code for handling resize...
      const phoneImg = document.getElementById('phone')
      const phoneWidth = phoneImg.clientWidth - 30
      const phoneHeight = phoneImg.clientHeight - 40

      let talentVideo = document.querySelector('#talent-video')
      talentVideo.style.width = phoneWidth + 'px'
      talentVideo.style.height = phoneHeight + 'px'
    },
    handleNoSlug(displayName) {
      this.snackbarMessage = `${displayName}'s Profile is Coming Soon!`
      this.showSnackbar = true
    },
    getSwitchBoardCategory(index) {
      return switchBoardCategories[index]
    },
    async loadMore() {
      this.loading = true
      this.skip += this.first
      await this.getAllTalent()
      this.loading = false
    },
    // Get all market place data
    async getAllTalent() {
      this.$loading = true
      const {
        data: { getTalentList },
      } = await this.$apollo.query({
        query: GET_ALL_TALENT,
        fetchPolicy: 'network-only',
        variables: {
          pagination: {
            first: this.first,
            skip: this.skip,
          },
        },
      })
      if (getTalentList.success) {
        this.paginatedTalents = getTalentList.talentList
        this.totalTalentCount = getTalentList.totalRecords
      }
      this.$loading = false
    },

    // Get featured talent
    async getFeaturedTalent() {
      this.featuredLoading = true
      const {
        data: { getFeaturedHosts },
      } = await this.$apollo.query({
        query: FEATURED_TALENT,
        variables: {
          pagination: {
            first: 0,
            skip: 0,
          },
        },
      })
      if (getFeaturedHosts.success) {
        this.allFeaturedTalent = getFeaturedHosts.talentList.filter(
          talent => talent.user.profileImg != null
        )
      }
      this.featuredLoading = false
    },

    // Search talent
    async searchTalent() {
      if (
        this.filter.zipcode == '' &&
        this.filter.city == '' &&
        this.filter.talentSubCategoryId == ''
      ) {
        this.first = 15
        this.skip = 0
        this.paginatedTalents = []
        this.isQuerySearch = true
        this.totalTalentCount = 0
        this.getAllTalent()
      } else {
        this.featuredLoading = true
        const {
          data: { searchTalent },
        } = await this.$apollo.query({
          query: SEARCH_TALENT,
          fetchPolicy: 'network-only',
          variables: {
            searchInput: {
              zipcode: this.filter.zipcode,
              city: this.filter.city,
              talentArea: this.filter.talentSubCategoryId,
              pagination: {
                first: this.first,
                skip: this.skip,
              },
            },
          },
        })
        this.paginatedTalents =
          searchTalent.talentList == null ? [] : searchTalent.talentList
        this.totalTalentCount = searchTalent.totalRecords
        this.isQuerySearch = false
        this.featuredLoading = false
      }
    },
    async getTalentCategories() {
      const {
        data: { getTalentArea },
      } = await this.$apollo.query({
        query: GET_TALENT_AREA,
        variables: { toplevel: true },
      })
      this.talentCategories = [...getTalentArea]
      const firstCategory = this.talentCategories.find(
        talentCategory => talentCategory.order === 0
      )
      this.category = firstCategory.name
      this.getTalentSubCategories(this.category)
    },
    async getTalentSubCategories(categoryName) {
      const {
        data: { getTalentArea },
      } = await this.$apollo.query({
        query: GET_TALENT_AREA,
        fetchPolicy: 'network-only',
        variables: { childOf: categoryName },
      })
      this.talentSubCategories = [...getTalentArea]
      this.filter.talentSubCategoryId = ''
      this.searchTalent()
    },
    toProfile(slug) {
      if (slug) this.$router.push(`/talent/profile/${slug}`)
    },
    updateSpotlight(e) {
      if (e.length) {
        this.filteredTalent = this.allTalent.filter(t => {
          let contains = false
          e.forEach(genre => {
            if (t.value.selectedGenres.includes(genre)) {
              contains = true
            } else {
              return false
            }
          })
          return contains
        })
      }
      if (!e.length) {
        this.filteredTalent = this.allTalent
      }
    },
    searchByName(e) {
      if (!this.selected) {
        this.filteredTalent = this.allTalent
      }
      const fResult = this.filteredTalent.filter(t => {
        const displayNameLowerCase = t.displayName.toLowerCase()
        const eLowerCase = e.toLowerCase()
        const includesRes = displayNameLowerCase.includes(eLowerCase)
        return includesRes
      })
      this.filteredTalent = fResult
      if (!e) {
        this.updateSpotlight(this.selected)
      }
    },
    marketPlace() {
      this.$vuetify.goTo(this.$refs.marketPlace, { behavior: 'smooth' })
    },
  },
}
</script>
<style lang="scss">
.home-featured-talent {
  .v-item-group {
    max-width: 110% !important;
  }
}
.section-heading {
  font-size: 25px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.63px;
}
.featured-talent-loader > .v-skeleton-loader__image {
  height: 500px !important;
  border-radius: 4px !important;
}

.talent-directory-bar {
  .v-toolbar__content {
    padding: 0px;
  }
  .v-toolbar__extension {
    padding: 0px;
  }
}
.v-btn-toggle--group > .v-btn.v-btn.v-btn--active {
  background-color: $secondary !important;
  color: #fff !important;
}
.sort-by > .v-btn-toggle > .v-btn.v-btn--active {
  background-color: #fff !important;
  color: transparent !important;
}
.sort-by-btn.v-btn.v-btn--active > .v-btn__content {
  color: $secondary !important;
}
.v-btn-toggle > .v-btn.v-btn {
  border-style: none !important;
  border-width: unset !important;
}
.talent-section {
  padding: 15px 0;
}
.home-main-image {
  background-position: top right !important;
  background-size: contain !important;
  // height: 130vh;
}
.home-main-section {
  padding: 150px 0 100px 0;
}
.experience {
  position: relative;
  filter: drop-shadow(0px 20px 62px rgba(101, 26, 0, 0.5));
  img {
    width: auto;
    max-width: 100%;
    visibility: hidden;
  }
  .fan-video {
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    left: 25px;
    bottom: 65px;
    width: 100px;
    video {
      width: 100%;
    }
  }
  .talent-video {
    border-radius: 40px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    // margin: 15px 24px;
    border: 20px solid #fff;
    border-left-width: 13px;
    border-right-width: 13px;
    width: 100%;
    video {
      height: 100%;
      margin-left: -120px;
    }
  }
}

.talent-directory-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 49rem !important;
}
.v-btn-toggle > .v-btn:not(.v-btn--active)::before {
  background-color: white !important;
}
.how-do-i-with-line {
  position: absolute;
  height: 5px;
  background: $primary;
  width: 80%;
  top: 50px;
  left: 85px;
}
.how-do-i-with-vertical-line {
  position: absolute;
  height: 200px;
  background: $primary;
}
.talent-info {
  background-color: #00000061;
}
@media screen and (max-width: 991px) {
  .home-main-image {
    height: 130vh;
  }
  .home-main-section {
    padding: 69px 0 85px 0;
  }
}
@media screen and (max-width: 670px) {
  .home-main-image {
    height: auto;
  }
  .experience .talent-video {
    // margin: 0;
    height: 90%;
  }
  .experience .fan-video {
    bottom: 85px;
  }
}
</style>
